import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_1/sub_1/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    quote1Text:  allMdx(filter: {frontmatter: {language: {eq: "DE"}, title: {eq: "slide-1-1-2"}}}) {
      edges {
        node {
          frontmatter {
            author
            authorInfo
            title
          }
          body
        }
      }
    },
  }
  `);

  return (
    <Template query={query} />
  );
};


export default Slide;
